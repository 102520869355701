@import "../colors";
@import "../mixins";

.NewPasswordForm{
    margin: 150px auto 200px;
    padding: 40px 40px;
    width: 25%;
    font-size: 1.4rem;
    border-radius: 20px;
    //@include double-gradient(0deg, $main-white, $main-light); <-- ale sraka xd
    input{
        margin: 10px 0;
    }
    label{
        margin-bottom: 10px;
    }
}