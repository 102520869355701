@import "../colors";
@import "../mixins";

.confirmation {
  margin: 100px auto 325px;
    width: 25%;
}
.resetError {
    color: $danger;
    font-size: 1.2rem;
    font-weight: bold;
}
.passwordResetForm {
    //box-shadow: 0 4px 7px rgba(0,0,0,.12); <-- wygląda do pizdy
    margin: 150px auto 200px;
    padding: 40px 40px;
    width: 25%;
    font-size: 1.4rem;
    border-radius: 20px;
    //@include double-gradient(0deg, $main-white, $main-light); <-- ale sraka xd
    input{
        margin: 10px 0;
    }
    label{
        margin-bottom: 10px;
    }
}

.NewPasswordForm{
    margin: 150px auto 200px;
    padding: 40px 40px;
    width: 25%;
    font-size: 1.4rem;
    border-radius: 20px;
    //@include double-gradient(0deg, $main-white, $main-light); <-- ale sraka xd
    input{
        margin: 10px 0;
    }
    label{
        margin-bottom: 10px;
    }
}