@import "../colors";
@import "../mixins";

.header {
  display: flex;
  flex-direction: row;
  margin: 15px;
  padding: 15px;
  border-radius: 20px;
  @include double-gradient(90deg, $main-light, $main-dark);

  @media (max-width: $breakpoint-tablet) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &-logo{
    background: $main-white;
    padding: 15px;
    border-radius: 20px;
    > img {
      width: 100px;
      height: 100px;
    }
  }

  &-name {
    width: 65%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: start;
    color: $main-white;
    @media (max-width: $breakpoint-desktop) {
      width: 45%;
    }
    > h1 {
      margin: 0;
    }
    @media (max-width: $breakpoint-tablet) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  &-user {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    @media (max-width: $breakpoint-tablet) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 99%;
    }
    &-button {
      background-color: $main-lighter;
      border-radius: 13px;
      font-size: 1.3rem;
      color: $black;
      padding: 10px;
      border: none;
      font-weight: bold;
      margin: 10px;
      cursor: pointer;
      text-align: center;
      height: 45px;
      transition: transform ease-in .1s;
      transform: translateY(0);
      &:hover{
        background-color: $main-light;
        transform: translateY(2px);
        transition: transform ease-in .1s;
      }
      &:active{
        background-color: $white;
        img {
          //filter: opacity(0.3) drop-shadow(0 0 0 $white);
          filter: opacity(0.7);
        }
      }
      &.ping{
        position: relative;
      }
      img {
        width: 25px;
        height: 25px;
        display: block;
      }
      .ping-mark {
        background-color: $danger;
        width: 5px;
        height: 5px;
        position: absolute;
        border-radius: 50%;
        right: 7px;
        top: 7px;
      }
    }
  }
  #notifications {
    position: relative;
    .notificationsDiv {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notificationsList, .notificationsDiv {
      position: absolute;
      padding: 10px 20px;
      margin: 0;
      right: 10px;
      top: 22.5px;
      list-style: none;
      background: $main-lighter;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
      overflow: auto;
      max-height: 600px;
      z-index: 10;
      &-item{
        display: flex;
        flex-direction: row;
        margin: 10px 0;
        &-text {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 10px;
          padding: 12px 0;
          max-width: 550px;
        }

        &-btn {
          background: $white;
          cursor: pointer;
          border-radius: 10px;
          width: 35px;
          height: 35px;
          margin: auto 5px;
          &.accept {
            background: $success-light;
          }

          &.refuse {
            background: $danger-light;
          }

          &-icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

