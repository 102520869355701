@import "../colors";
@import "../mixins";

.error-page-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 20px;
  width: calc(100% - 40px);

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column-reverse;
  }

  h1{
    font-size: 50px;
  }

  img{
    max-width: 40%;
  }

  @media (max-width: $breakpoint-desktop) {
    max-width: 100%;
  }

  .logo-section {
    width: 100%;
    text-align: center
  }
}

.button-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}