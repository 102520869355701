@import url("../components/header.scss");
@import "../colors";
@import "../mixins";

main {
  .login-form {
    box-shadow: 0 4px 7px rgba(0,0,0,.12);
    width: 30%;
    margin: auto;
    margin-top: 100px;
    padding: 0 40px;
    @include double-gradient(0deg, $main-white, $main-light);


    .password-wrapper {
      @include input-password-wrapper($black, $main-white, $main-lighter);
    }

    &-title {
      padding: 40px;
      text-align: center;
      color: $black;
    }
    &-company {
      background-color: $white;
      display: flex;
      flex-direction: row;
      padding: 12px;
      border-radius: 20px;
      text-decoration: none;
      pointer-events: none;
      > span {
        padding: 8px 35px;
        color: $black;
        font-weight: bold;
        font-size: 1.5rem;
        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
        }
      }
      > img {
        width: 50px;
        height: 50px;
      }
    }
    &-breakpoint {
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      color: $black;
    }
    &-email {
      @include input-text( $black-light, $main-white, $main-lighter );
    }
    &-password {
      @include input-text( $black-light, $main-white, $main-lighter );
    }
    &-remember {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 35px 0;
      span {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        padding-left: 20px;
        color: $black;
      }
    }
    &-remember-wrapper {
      @include input-checkbox( $black, $white, $main-dark);
    }
    &-submit {
      @include button( $success-light, $black-light );
    }
    &-forgotPasswd {
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      margin: 0;
      padding: 20px;
      text-decoration: underline;
      color: $black;
      > a {
        &:visited, &:active {
          color: $black;
        }
      }
    }
    &-signUp {
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      color: $black;
      width: 30%;
      margin: auto;
      margin-bottom: 60px;
      span{
        margin-right: 10px;
      }
      > a {
        &:visited, &:active {
          color: $black;
        }
      }
    }
    @media (max-width: $breakpoint-mobile) {
      width: 70%;
    }
  }
}