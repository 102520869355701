@import "colors";
@mixin double-gradient($degrees, $main-color, $secondary-color) {
  background: $main-color;
  background: -moz-linear-gradient($degrees, $main-color 0%, $secondary-color 100%);
  background: -webkit-linear-gradient($degrees, $main-color 0%, $secondary-color 100%);
  background: linear-gradient($degrees, $main-color 0%, $secondary-color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$main-color,endColorstr=$secondary-color,GradientType=1);
}

@mixin button ($background, $font-color) {
  background-color: $background;
  padding: 20px;
  color: $font-color;
  font-weight: bold;
  border-radius: 20px;
  width: 100%;
  font-size: 1.3rem;
  border: none;
  transition: transform 0.1s linear;
  &:hover {
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-2px);
    cursor: pointer;
    transition: transform 0.1s linear;
  }
  :active {
    transform: translateY(2px);
    transition: transform 0.1s linear;
  }
}

@mixin input-text($font-color, $background-color, $focus-background-color){
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 5px;
  width: calc( 100% - 40px );
  font-size: 1.3rem;
  padding: 25px 20px;
  color: $font-color;
  background-color: $background-color;
  &:focus {
    background-color: $focus-background-color;
  }
}

@mixin input-password($font-color){
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 5px;
  width: calc( 100% - 30px );
  font-size: 1.3rem;
  padding: 25px 20px;
  color: $font-color;
}
@mixin input-password-wrapper($font-color, $background-color, $focus-background-color) {
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
  background-color: $background-color;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;

  &:focus-within {
    background-color: $focus-background-color;
    outline: 3px solid $font-color;
  }

  input {
    border: none;
    background-color: $background-color;
    width: 100%;
    padding: 25px 20px;
    color: $font-color;
    font-size: 1.3rem;
    &:focus {
      outline: none;
      background-color: $focus-background-color;
    }
  }

  .password-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    img {
      width: 50px;
      height: 50px;
    }
  }
}

@mixin input-checkbox( $dot-color, $default-color, $checked-color ) {
  .tgl {
    display: none;
  }
  .tgl-btn {
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.1)) drop-shadow(0 0 16px #fff);
    &:focus {
      outline: 2px solid #000; /* lub inny kolor obramowania */
    }
  }

  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .tgl::-moz-selection,
  .tgl:after::-moz-selection,
  .tgl:before::-moz-selection,
  .tgl *::-moz-selection,
  .tgl *:after::-moz-selection,
  .tgl *:before::-moz-selection,
  .tgl + .tgl-btn::-moz-selection,
  .tgl::selection,
  .tgl:after::selection,
  .tgl:before::selection,
  .tgl *::selection,
  .tgl *:after::selection,
  .tgl *:before::selection,
  .tgl + .tgl-btn::selection {
    background: none;
  }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .tgl + .tgl-btn:after,
  .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .tgl + .tgl-btn:after {
    left: 0;
  }
  .tgl + .tgl-btn:before {
    display: none;
  }
  .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .tgl-light + .tgl-btn {
    background: $default-color;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
  }
  .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: $dot-color;
    transition: all 0.2s ease;
  }
  .tgl-light:checked + .tgl-btn {
    background: $checked-color;
  }
  .tgl-btn:focus {
    outline: 2px solid black;
  }
}



@mixin input-checkbox-small($background-color, $checkmark-color, $focus-color) {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus + .checkmark {
      outline: 3px solid $focus-color;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $checkmark-color;
    border-radius: 50px;
    border: 2px solid $background-color;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked + .checkmark {
    background-color: $background-color;
    border: 2px solid $background-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked + .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $checkmark-color;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}



@mixin input-date($font-color, $background-color)
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input,select {
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 5px;
    width: calc( 100% - 30px );
    font-size: 1.3rem;
    padding: 25px 20px;
    color: $font-color;
    background-color: $background-color;
  }
  input{
    width: 20%;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  select {
    width: 40%;
    cursor: pointer;
  }
}

@mixin input-time($font-color, $background-color)
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 5px;
    width: calc( 100% - 30px );
    font-size: 1.3rem;
    padding: 25px 20px;
    color: $font-color;
    background-color: $background-color;
  }
  input{
    width: 45%;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  span{
    width: 10%;
    text-align: center;
    font-size: 1.3rem;
    padding: 25px 20px;
  }
}

@mixin event-cover()
{
  background: white;
  border-radius: 10px;

  // cover size multiplier

  &.dp-large {
    width: $cover-size * 24px;
    height: $cover-size * 36px;
    &:is(span){
      font-size: 10rem;
    }
  }
  &.dp-medium {
    width: $cover-size * 18px;
    height: $cover-size * 24px;
    &:is(span){
      font-size: 7rem;
    }
  }
  &.dp-small {
    width: $cover-size * 11px;
    height: $cover-size * 17px;
  }
  @media (max-width: $breakpoint-mobile) {
    width: $cover-size * 24px;
    height: $cover-size * 36px;
    &:is(span){
      font-size: 10rem;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    width: $cover-size * 18px;
    height: $cover-size * 24px;
    &:is(span){
      font-size: 7rem;
    }
  }
  @media (max-width: $breakpoint-desktop) {
    width: $cover-size * 11px;
    height: $cover-size * 17px;
  }

  &:is(span)
  {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5rem;
    font-weight: 600;
  }
}