$main-dark: #9184FF;
$main: #AEA5FF;
$main-light: #e8e6ff;
$main-lighter: #F4F2FF;
$main-white: #FBFBFF;
$white: #ffffff;


$danger: #ff7474;
$danger-light: #ffc5c5;
$success: #97ffa2;
$success-light: #cdffd5;


$black: #2B2B2B;
$black-light: #3D3D3D;


$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

$cover-size: 12;