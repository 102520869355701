@import "../colors";
@import "../mixins";

.univForm-container {
  background-color: $main-lighter;
  padding: 20px;
  width: 70%;
  border-radius: 20px;
  margin: auto;

  @media (max-width: $breakpoint-desktop) {
    padding: 20px;
    width: calc(100% - 80px);
    margin: 20px;
  }

  &-title {
    width: 100%;
    font-size: 1.65rem;
    margin: 0;
    color: $black;
    font-weight: 900;
  }

  p {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  &-label, &-toggle-label {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      width: 100%;
    }

    &-title {
      font-size: 1.4rem;
      color: $black;
      font-weight: 800;
      margin-top: 20px;
      margin-bottom: 0;
    }

    &-caption {
      font-size: 1rem;
      color: $black-light;
      font-weight: 700;
      margin: 12.5px 0;
    }
  }

  fieldset {
    border: none;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
  }

  &-textInput {
    padding: 15px;
    font-size: 1.1rem;
    border-radius: 10px;
    border: none;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
    width: calc(100% - 30px);
  }

  &-bigTextInput {
    font-size: 1.1rem;
    border-radius: 10px;
    border: none;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
    width: calc(100%);
    background-color: $main-white;

    .ql-toolbar.ql-snow {
      border: none;
      border-radius: 10px;
      background-color: $main-light;
    }

    .ql-container.ql-snow {
      border: none;
      font-size: 1rem;
      font-family: "Inter", serif;
    }
  }

  &-mapInput {
    height: 450px;
    width: 100%;
    margin: auto;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    > div {
      border-radius: 20px;
    }
  }

  &-submitInput {
    margin: 50px auto auto;
    @include button($success-light, $black);
    width: 50%;
  }

  .error {
    border: 3px solid $danger;

    &:focus-visible {
      outline: none;
    }
  }

  .file-error{
    color: $danger;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &-date {
    @include input-date($black-light, $main-white);
    padding: 15px 15px 15px 0;
    font-size: 1.1rem;
    width: 40%;
    margin-left: 40px;
    @media (max-width: $breakpoint-tablet) {
      width: 80%;
      gap: 4px;
      margin-left: 0;
    }
    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 45px);
      padding: 15px 0 15px 0;
    }

    input, select {
      padding: 15px;
      font-size: 1.1rem;
    }
  }

  &-time {
    @include input-time($black-light, $main-white);
    padding: 15px 15px 15px 0;
    font-size: 1.1rem;
    width: 15%;
    @media (max-width: $breakpoint-tablet) {
      width: 50%;
    }
    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 45px);
      padding: 15px 0 15px 0;
    }

    input, span {
      padding: 15px;
      font-size: 1.1rem;
      @media (max-width: $breakpoint-desktop) {
        padding: 15px 10px;
      }
      @media (max-width: $breakpoint-tablet) {
        padding: 15px 7.5px;
      }
      @media (max-width: $breakpoint-mobile) {
        padding: 15px 5px;
      }
    }
  }

  &-dateTimeIcons {
    height: 35px;
    width: 35px;
    margin: 25px 5px;
  }

  &-toggle {
    @include input-checkbox($black, $white, $main-dark);
    width: 33%;
  }

  .toggle-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      align-items: start;
    }

    .univForm-container-toggle {
      width: 33%;
      height: 100%;
      @media (max-width: $breakpoint-desktop) {
        width: 100%;
      }
      > .btn {
        width: 100%;
      }
      > a.btn{
        width: calc(100% - 40px);
        text-align: center;
      }
    }
  }
  &-file {
    width: calc( 100% - 50px);
    cursor: pointer;
    background-color: $main-light;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    padding: 25px;
    border-radius: 20px;
    font-weight: bold;

    &-caption {
      color: $black-light;
      font-size: 1.3rem;
      &.hidden{
        visibility: hidden;
      }
    }
    &-icon {
      width: 50px;
      height: 50px;
      margin: 15px
    }
    &-input { display: none; }

    &-imageWrapper{
      display: flex;
      flex-direction: row!important;
      justify-content: space-evenly;
      align-items: center!important;
      margin: 30px 0;

      @media (max-width: $breakpoint-desktop) {
        flex-direction: column!important;
      }
    }
    &-image {
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
      @include event-cover();
    }
    &-trash {
      display: block;
      width: 40px;
      height: 40px;
      cursor: pointer;
      padding: 10px;
      background-color: $danger-light;
      border-radius: 10px;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
      margin: auto;
    }
  }
}

#users-list {
  position: relative;

  #list-root {
    position: absolute;
    background-color: $main-lighter;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.08);
    border-radius: 10px;

    p {
      margin: 0;

      button {
        display: flex;
        flex-direction: row;
        width: calc( 100% - 40px);
        background: $main-white;
        border: none;
        align-items: center;
        margin: 10px 20px;
        border-radius: 10px;
        padding: 0 10px;
        cursor: pointer;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.08);
        h1{
          font-size: 1.2rem;
          margin-right: 15px;
          color: $black;
          font-weight: 500;
        }
        h2{
          font-size: 1rem;
          color: $black-light;
          font-weight: 600;
        }
      }
    }
  }
}