@import "../colors";
@import "../mixins";

.events-list{
  margin: 40px 20px 40px 20px;

  &-events{
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 10px 0;
    &-item{
      display: block;
      text-decoration: none;
      color: $black;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      margin: 10px 20px;
      transition: scale, box-shadow .1s ease-in;
      position: relative;
      &:first-of-type{
        margin: 10px 20px 10px 10px;
      }
      &:last-of-type{
        margin: 10px 0 10px 20px;
      }
      &:active, &:visited, &:focus {
        text-decoration: none;
        color: $black;
      }
      &:hover, &:focus {
        scale: 1.01;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
        transition: scale, box-shadow .1s ease-out;
      }
      &-image{
        @include event-cover();
        &-settings {
          bottom: 20%;
          height: 50px;
          position: absolute;
          right: 0;
          width: 50px;
          margin: 10px;
          filter: drop-shadow(0px 0px 15px white);
        }
      }
      &-title {
        padding: 25px 10px;
        margin: 0;
        max-width: ($cover-size * 24px) - 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $black;

      }

      @media (max-width: $breakpoint-mobile) {
        display: block;
        text-decoration: none;
        color: $black;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        margin: 10px 20px;
        transition: scale, box-shadow .1s ease-in;
        
      }
    }
  }
  &-title {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 4px;
    color: $black;
    text-decoration: none;
    padding-left: 10px;
    transition: letter-spacing .1s ease-in;
    &:active, &:visited, &:focus {
      text-decoration: none;
      color: $black;
    }
    &:hover, &:focus {
      letter-spacing: 4.5px;
      transition: letter-spacing .1s ease-out;
    }
    @media (max-width: $breakpoint-mobile) {
      display: flex;
      justify-content: center;
      text-align: center;
      justify-content: center;
      font-size: 32px;
    }
  }
}

a.events-list-title {
  cursor: pointer;
}