@import "../colors";
@import "../mixins";

.footer {
    .btn-footer {
        display: flex;
        border: none;
        background-color: $main-dark;
        padding: 10px;
        border-radius: 20px;
        align-items: center;
        margin-bottom: 10px;

        span {
            color: $black;
            font-size: 1.3rem;
            font-weight: 700;
        }
        img {
            height: 40px;
            width: 40px;
        }
    }
    &-lang{
        display: flex;
        justify-content: end;
        margin-right: 20px;
        &-btn{
            cursor: pointer;
            img {
                margin-right: 10px;
            }
        }
    }

    &-company {
        background-color: $main-lighter;
        padding: 5px;
        &-signature{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;

            &-name{
                font-size: 1.4rem;
                font-weight: 700;
                margin-top: 15px;
            }
            &-logo{
                height: 30px;
                width: 30px;
                margin-left: 6px;
                margin-top: 15px;

            }
        }
        &-rights{
            display: flex;
            justify-content: end;
            margin-right: 25px;
            span{
                font-weight: 500;
                color: $black-light;
            }
        }
    }

    &-links {
        display: flex;
        width: calc(100% - 40px);
        padding: 20px;
        justify-content: space-between;



        &-app {
            display: flex;
            width: 85%; // Adjusted width to leave space for social media links
            justify-content: space-between;
            
            @media (max-width: $breakpoint-mobile) {
                display: flex;
                flex-wrap: wrap;
              }

            &-list {
                ul {
                    list-style: none;
                    padding: 0; // Reset padding
                }
                h2 {
                    font-size: 1.2rem;
                    font-weight: 700;
                    margin-bottom: 25px;
                }
                &-item {
                    margin-bottom: 10px;

                    a {
                        text-decoration: none;
                        color: $black;
                        font-weight: 500;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &-socialMedia {
            width: 20%; // Adjusted width for social media section
            display: flex;
            flex-direction: column;
            align-items: end; // Align items to the start

            &-link {
                margin-bottom: 9px;

                &-icon {
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
}
