@import "../colors";
@import "../mixins";

.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.668);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
}
.confirmation-text{
    color: $black;
}

.confirmation-box {
    background: $main-white;
    padding: 45px 80px;
    border-radius: 20px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}


.confirmation-buttons {
    display: flex;
    justify-content: space-around;
}

.confirmation-button {
    width: 80px;
}
/*
.confirmation-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}



.confirmation-button:hover {
    background-color: #eee;
}
*/
