@import "../colors";
@import "../mixins";

#event-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  background-color: $main-lighter;
  border-radius: 20px;
  padding: 20px;

  .event-header {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;

      &-element {
        @include event-cover();
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);

        &-container {
          margin: 0;
        }
      }
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-title {
        margin: 0;
        padding: 20px;
        text-align: center;
        font-size: 2.3rem;
        color: $black-light;
      }

      &-data {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 30px 0;

        @media (max-width: $breakpoint-desktop) {
          flex-direction: column;
        }

        &-datetime {
          width: calc(50% - 250px);
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          padding: 0 125px;
          position: relative;

          @media (max-width: $breakpoint-desktop) {
            padding: 0 20px;
            width: calc( 100% - 40px );
          }

          &:focus {
            outline: 2px solid #000;

            .event-header-content-data-datetime-calendar-info {
              backdrop-filter: blur(1.5px);
              cursor: pointer;
              transition: backdrop-filter .1s ease-out;

              span {
                visibility: visible;
                color: $black;
                transition: color .1s ease-in;
              }
            }
          }

          &-calendar-info {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: backdrop-filter .1s ease-in;

            span {
              visibility: hidden;
              font-size: 2rem;
              font-weight: bold;
              color: transparent;
              transition: color .1s ease-in;
              filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 16px #fff);
            }

            &:hover {
              backdrop-filter: blur(1.5px);
              cursor: pointer;
              transition: backdrop-filter .1s ease-out;

              span {
                visibility: visible;
                color: $black;
                transition: color .1s ease-in;
              }
            }
          }

          &-legend {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-icon {
              width: 50px;
              height: 50px;
            }
          }

          &-datetime {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-date, &-time {
              font-size: 1.3rem;
              color: $black;
              text-align: center;
              margin: 13px 0;

              @media (max-width: $breakpoint-mobile) {
                font-size: 1rem;
              }
            }
          }

          &-icon {
            width: 50px;
            height: 50px;
            margin-top: auto;
            margin-bottom: auto;

            @media (max-width: $breakpoint-mobile) {
              width: 40px;
              height: 40px;
            }
          }
        }

        &-info {
          display: flex;
          flex-direction: column;
          width: 50%;
          justify-content: space-between;

          @media (max-width: $breakpoint-desktop) {
            padding: 20px;
            width: calc( 100% - 40px );
          }

          &-location, &-supervisor {
            display: flex;
            flex-direction: row;

            a, a:active, a:hover, a:visited {
              color: $black;
            }

            &-container {
              margin: 0;
              padding: 0;
              font-size: 1.4rem;
              display: flex;
              align-items: center;

              @media (max-width: $breakpoint-mobile) {
                font-size: 1rem;
              }

              img {
                width: 50px;
                height: 50px;
                margin-right: 20px;

                @media (max-width: $breakpoint-mobile) {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }

          &-supervisor-container {
            font-weight: bold;
            color: $black;
          }
        }
      }
    }
  }
}

.event-timers {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto auto;

  &:has(> *:only-child) {
    justify-content: center;
  }

  &-start, &-end {
    display: flex;
    flex-direction: column;

    &-title {
      padding: 10px 0;
      font-size: 1.3rem;
    }

    &-time {
      border-radius: 15px;
      background-color: $main;
      color: $white;
      font-weight: bold;
      font-size: 2.7rem;
      padding: 15px 40px;
      width: 170px;
      text-align: center;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    }
  }
}

.event-calendar {
  background-color: $main-lighter;
  padding: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35);
  @media (max-width: $breakpoint-mobile) {
    flex-wrap: wrap;
    width: calc(100% - 60px);
    gap: 8px;
    justify-content: center;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }

  &-day {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    padding: 3px 0;
    width: 90px;
    height: 84px;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px;

    @media (max-width: $breakpoint-desktop) {
      width: 70px;
      height: 64px;
      margin: 0 4px;
    }

    @media (max-width: 630px) {
      width: 50px;
      height: 44px;
      margin: 0 3px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin: 0;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &-date, &-month, &-weekday {
      color: $black-light;
      text-align: center;
    }

    &-date {
      font-size: 2.4rem;
      font-weight: bold;

      @media (max-width: $breakpoint-desktop) {
        font-size: 1.7rem;
      }

      @media (max-width: 630px) {
        font-size: 1rem;
      }
    }

    &-month {
      font-size: .8rem;
      text-transform: lowercase;

      @media (max-width: 630px) {
        font-size: .5rem;
      }
    }

    &-weekday {
      font-size: 1.3rem;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: $breakpoint-desktop) {
        font-size: .9rem;
      }

      @media (max-width: 630px) {
        font-size: .6rem;
      }
    }

    &-current {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -25px;

      @media (max-width: $breakpoint-mobile) {
        width: 15px;
        height: 15px;
        bottom: -7.5px;
      }
    }

    &.event-day {
      background-color: $main;
      transition: transform .1s ease-out;

      &:hover {
        cursor: pointer;
        transform: translateY(2px);
        transition: transform .1s ease-in;
      }
    }

    &.current-day {
      position: relative;
    }
  }
}

.event-schedule {
  margin: 60px 15px 15px;

  &-day {
    background-color: $main-lighter;
    border-radius: 20px;
    padding: 10px;
    margin-top: 50px;

    &-container {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      &-list {
        width: 30%;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }

        &-element {
          display: flex;
          flex-direction: column;
          align-items: end;
          margin: 0 10px;

          &:last-of-type {
            margin-top: 10px;
          }

          .segment-list-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 0;

            &-dot {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: $main;
            }

            &-text {
              padding: 10px;
              font-size: .9rem;
              padding-left: 20px;
            }
          }

          .segment-list-content-name {
            margin: 0;
            padding: 20px;
            border-left: 2px solid $main;
            width: calc(100% - (40px + 8px));
            font-weight: bold;
            font-size: 1.2rem;
            transition: transform .15s ease-in;

            &:hover {
              transform: translateX(10px);
              transition: transform .15s ease-out;
              cursor: pointer;
            }
          }

          .segment-list-content-info {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 20px;
            border-left: 2px solid $main;
            width: calc(100% - (40px + 8px));
            font-weight: bold;
            font-size: 1.2rem;

            &-location {
              margin-top: 0;
            }

            &-time {
              margin-bottom: 0;
            }

            &-location, &-speaker, &-time {
              margin: 10px 0;
              display: flex;
              flex-direction: row;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
              }

              span {
                padding: 5px 20px;
                color: $black;
              }
            }
          }
        }
      }
    }

    &-description {
      width: 70%;
      border-radius: 15px;
      background-color: $white;
      padding: 15px;

      @media (max-width: $breakpoint-tablet) {
        width: calc( 100% - 30px);
        margin-top: 10px;
      }

      &-header {
        color: $black-light;
        font-size: 1.5rem;
        width: calc(100% - 30px);
        text-align: center;
        padding: 15px;
        padding-top: 0;
        font-weight: bold;
      }
    }

    &-segment {

    }

    &-header {
      display: flex;
      flex-direction: row;

      &-date {
        padding: 20px 15px;
        background-color: $white;
        border-radius: 15px;

        &-day {
          color: $black;
          font-size: 1.5rem;
          font-weight: bold;
        }

        &-month {
          color: $black;
          font-size: 1rem;
          text-transform: lowercase;
        }
      }

      &-weekday {
        text-transform: uppercase;
        color: $black-light;
        font-size: 1.8rem;
        width: calc(100% - 30px);
        text-align: center;
        padding: 15px;
        font-weight: bold;
      }
    }
  }
}

#event-desc {
  .event-desc {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 15px;
      background-color: $main-lighter;
      border-radius: 20px;
      padding: 20px;

      &-desc, &-rules {
        width: calc(100% - 40px);
        height: 100%;
        background-color: $white;
        border-radius: 20px;
        padding: 20px;
      }
    }

    &-controls {
      margin: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      span {
        font-size: 1.3rem;
        padding: 0 5px;
        font-weight: 500;
        color: $black;
      }

      &-wrapper {
        @include input-checkbox($black, $white, $main-dark);
      }
    }
  }
}

.event-page-wrapper {
  .btn {
    margin: 20px;
  }
}