@import url("../components/header.scss");
@import "../colors";
@import "../mixins";

.page-info {
  width: 100%;
  &-dots {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    &-dot {
      width: 25px;
      height: 25px;
      background-color: $main-light;
      display: block;
      border-radius: 50px;
      &.active {
      background-color: $main-dark;
      }
    }
  }
  &-back {
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      font-size: 1.2rem;
      transform: translateX(-50px);
      transition: transform .1s ease-in;
      padding: 0;
      > img {
        margin-right: 15px;
        width: 35px;
        height: 35px;
      }
      &:hover {
        transform: translateX(-45px);
        transition: transform .1s ease-out;
      }
    }
    :hover {
      cursor: pointer;
    }
  }
}

.btn-next {
  @include button( $success-light, $black-light );
  margin-top: 40px;

  @media (max-width: $breakpoint-mobile) {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

h2.form-page-title {
  text-align: center;
  margin: 25px 0 15px;
  font-weight: bold;
  color: $black;
}
h3.form-page-title {
  text-align: center;
  color: $black-light;
  margin: 25px 0 15px;
  font-weight: 600;
}

.form-container{
  display: flex;
  justify-content: center;
  padding-top: 30px;
  &-email {
    width: 30%;
    &-address {
      @include input-text($black, $main-white, $main-lighter);
      margin-top: 40px;
    }
    &-company {
      background-color: $white;
      display: flex;
      flex-direction: row;
      padding: 12px;
      border-radius: 20px;
      text-decoration: none;
      margin: 20px 0;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12);
      > span {
        padding: 8px 35px;
        color: $black;
        font-weight: bold;
        font-size: 1.5rem;
        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
        }
      }
      > img {
        width: 50px;
        height: 50px;
      }
    }
    &-signIn {
      font-weight: bold;
      font-size: 1.3rem;
      color: $black;
      text-align: center;
      margin: 50px;
      span {
        margin-right: 15px;
      }
      > a {
        &:visited, &:active {
          color: $black;
        }
      }
    }
    &-breakpoint {
      text-align: center;
      color: $black-light;
      margin: 25px 0 15px;
      font-weight: 600;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 70%;
    }
  }
  &-password {
    width: 30%;
    .password-wrapper {
      @include input-password-wrapper($black, $main-white, $main-lighter);
    }
    &-validation {
    }
    @media (max-width: $breakpoint-mobile) {
      width: 70%;
    }
  }
  &-info {
    width: 30%;
    &-username, &-name, &-surname {
      @include input-text($black, $main-white, $main-lighter);
    }
    .date-picker {
      @include input-date($black-light, $main-white);
    }
    &-h3 {
      text-align: start;
      color: $black-light;
      margin: 25px 0 15px;
      font-weight: 600;
    }
    &-h2 {
      text-align: start;
      margin: 40px 0 15px;
      font-weight: bold;
      color: $black;
    }
    &-errors {
      font-size: 1.2rem;
      font-weight: bold;
      color: $danger;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 80%;
    }
  }
  &-tos {
    width: 30%;
    a {
        color: $black;
      :active {
        color: $black;
      }
    }
    &-agreement {
      padding: 20px;
      border-radius: 5px;
      background-color: $main-light;
      color: $black;
      .agreement {
        margin-bottom: 0!important;
        color: $black-light!important;
      }
    }
    &-closure {
      font-size: 1.2rem;
      font-weight: 400;
      a {
        margin-left: 10px;
      }
    }
    &-error {
      font-size: 1.2rem;
      font-weight: bold;
      color: $danger;
    }
    
    @media (max-width: $breakpoint-mobile) {
      width: 80%;
    }
    
  }
  &-confirmation {
    width: 30%;
    .page-info {
      margin-bottom: 75px;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      width: 70%;
    }
  }
}


.checkbox-container, .radio-container, .agreement{
  @include input-checkbox-small($main-dark, $main-white, $black);
}

.form {
  &-enter {
    opacity: 0;
    transform: translateX(100%);
    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
  }
  &-exit {
    opacity: 1;
    transform: translateX(-100%);
    &-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
  }
  &-reverse-enter {
    opacity: 0;
    transform: translateX(-100%);
    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
  }
  &-reverse-exit {
    opacity: 1;
    transform: translateX(0);
    &-active {
      opacity: 0;
      transform: translateX(100%);
      transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
  }
}

.error {
  border: 3px solid $danger;
  &:focus-visible {
    outline: none;
  }
}
